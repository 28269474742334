/*
 * Copyright 2018 The Kubeflow Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';

type Orientation = 'horizontal' | 'vertical';
interface SeparatorProps {
  orientation?: Orientation;
  units?: number;
}

const style = (orientation: Orientation, units: number) => {
  return orientation === 'horizontal'
    ? {
        display: 'inline-block',
        minWidth: units,
        width: units,
      }
    : {
        display: 'block',
        flexShrink: 0,
        height: units,
        minHeight: units,
      };
};

const Separator = (props: SeparatorProps) => (
  <span style={style(props.orientation || 'horizontal', props.units || 10)} />
);

export default Separator;
